<template>
  <div class="mainContainer container" id="mainContainer">

    <div style="display:flex;align-items: center;">
      <back-button />
      <h6 style="margin:auto;padding-right:30px;">{{this.pageTitle  + ' - ' + userInfo.FULL_NAME}}</h6>
      <div class="history" @click="gotoBc()"><font-awesome-icon :icon="['fas', 'history']" /></div>
    </div>
    <hr>



    <div class="alert alert-danger d-flex expireBanner" v-if="account.EXPIRE_STATUS === '4'"><font-awesome-icon  class="expired expireIcon" :icon="['fas', 'exclamation-circle']"/> User account has expired! <div class="btn btn-success extend" @click="this.$root.gotoRoute({ path: '/extenduser' })">Extend</div></div>
    <div class="alert alert-warning d-flex expireBanner" v-if="account.EXPIRE_STATUS === '1'"><font-awesome-icon class="expiring expireIcon" :icon="['fas', 'exclamation-triangle']"/> User account is expiring! <div class="btn btn-success extend" @click="this.$root.gotoRoute({ path: '/extenduser' })">Extend</div></div>


    <div style="display:flex;justify-content: center;" v-if="(this.isAdmin || this.me.ID === this.userId) && account.EXPIRE_STATUS !== '4'">
      <button class="btn tranBtn inBtn" data-trantype="in" @click="tranBtnClicked('in')" :class="{active:inOut === 'in'}" v-if="this.isAdmin" ><font-awesome-icon :icon="['fas', 'plus-circle']" /> Money In </button>
      <button class="btn tranBtn outBtn" data-trantype="out" @click="tranBtnClicked('out')" :class="{active:inOut === 'out'}"><font-awesome-icon :icon="['fas', 'minus-circle']" /> Money Out </button>
    </div>

    <hr>

    <div style="display:flex;justify-content: center;">
      <button class="btn tranTypeBtn inBtn" @click="switchActualBudget('actual')" :class="{active:isBudget === '0'}" v-if="this.isAdmin || inOut === 'out'" ><font-awesome-icon :icon="['fas', 'dollar-sign']" /> Actual </button>
      <button class="btn tranTypeBtn outBtn" @click="switchActualBudget('budget')" :class="{active:isBudget === '1'}"><font-awesome-icon :icon="['fas', 'clock']" /> Schedule</button>
    </div>

    <div>
      <div id="newTranContainer">

        <hr>

        <section>
          <div class="sectionTitle">Transaction Account</div>
          <select class="form-control validate" :class="{ 'hasError': tranAccountHasError }" id="tranBa" v-model="tranBaId" @change="resetRelatedAccount">
            <option v-for="(userTranAccount) in userBas" :key="userTranAccount.BA_ID" :value="userTranAccount.BA_ID">{{userTranAccount.ACCOUNT_NAME}}</option>
          </select>
          <div class="balanceDiv" v-if="tranBaId">Balance: {{tranBaBalance}}</div>
        </section>

        <div v-if="isBudget==='1'">
          <section>
            <div class="sectionTitle">Repeat</div>
            <select id="frequency" class="form-control" v-model="repeatFrequency">
              <option v-for="frequency in repeatFrequencyOptions" :key="frequency.id" :value="frequency.value" >{{frequency.name}}</option>
            </select>
          </section>

          <div id="intervalDiv" v-if="repeatFrequency !== 'never'">
              <section>
                <div class="sectionTitle">Repeat every</div>
                <div class="input-group mb-3" >
                  <span class="input-group-text" @click="intervalAdj('minus')"><font-awesome-icon :icon="['fas', 'minus-circle']" /></span>
                  <input type="number" min="1" max="999" class="form-control interval" aria-label="Amount" v-model="interval">
                  <span class="input-group-text"  @click="intervalAdj('plus')"><font-awesome-icon :icon="['fas', 'plus-circle']" /></span>
                  <span class="intervalTxt">{{intervalTxt}}</span>
                </div>
              </section>

            <section id="weekday" v-if="repeatFrequency==='WEEKLY'">
              <div class="sectionTitle">Repeat On</div>
              <div >
                <ul id="weekdaysUl">
                  <li v-for="weekday in weekdays" :key="weekday.id" :class="{'active': weekdaySelected.includes(weekday.value)}" @click="toggleWeekdaySelection(weekday.value)">
                    {{weekday.text}}
                  </li>

                </ul>
              </div>
            </section>

            <section id="bymonthday" v-if="repeatFrequency==='MONTHLY' || repeatFrequency==='YEARLY' ">
              <div class="sectionTitle">Repeat On</div>
              <div class="subsection" v-if="repeatFrequency==='YEARLY'">
                <select class="form-control"  name="repeatOnMonth" v-model="bymonth">
                  <option v-for="month in months" :key="month.id" :value="month.value">{{month.name}}</option>
                </select>
              </div>

              <div >
                <ul id="monthlyRepeatOnUl">
                  <li class="subsection">
                    <div class="flex-left radioRow"><input class="form-check-input mt-0" type="radio" id="monthlyRepeatOnDay" value="dayofmonth" v-model="monthlyRepeatOn" name="monthlyRepeatOn" checked> <label for="monthlyRepeatOnDay">day of month</label></div>
                    <div>
                      <select class="form-control" v-model="bymonthday" @change="selectRadio('dayofmonth')">
                        <option value="-1">Last</option>
                        <option v-for="n in 31" :key="n" :value="n">{{n}}</option>
                      </select>
                    </div>
                  </li>
                  <li class="subsection">
                    <div class="flex-left radioRow"><input class="form-check-input mt-0" type="radio" id="monthlyRepeatOnWeekDay" value="weekday" v-model="monthlyRepeatOn"  name="monthlyRepeatOn"><label for="monthlyRepeatOnWeekDay">a weekday</label></div>
                    <div class="flex-left">
                      <select class="form-control" v-model="bysetpos" @change="selectRadio('weekday')">
                        <option value="1">First</option>
                        <option value="2">Second</option>
                        <option value="3">Third</option>
                        <option value="4">Fourth</option>
                        <option value="-1">Last</option>
                      </select>

                      <select class="form-control" v-model="byweekday" @change="selectRadio('weekday')">
                        <option v-for="weekday in weekdays" :key="weekday.id" :value="weekday.value">{{weekday.name}}</option>
                      </select>
                    </div>
                  </li>


                </ul>
              </div>
            </section>









            <section>
              <div class="sectionTitle">End</div>
              <select id="endOption" class="form-control" v-model="endOption">
                <option value="never">Never</option>
                <option value="until">Until</option>
                <option value="count">Count</option>
              </select>
            </section>


            <section v-if="endOption === 'count'">
              <div class="sectionTitle">Repeat Times</div>
              <div class="input-group mb-3" >
                <span class="input-group-text" @click="repeatTimesAdj('minus')"><font-awesome-icon :icon="['fas', 'minus-circle']" /></span>
                <input type="number" min="1" max="999" class="form-control interval" aria-label="Amount" v-model="repeatTimes">
                <span class="input-group-text"  @click="repeatTimesAdj('plus')"><font-awesome-icon :icon="['fas', 'plus-circle']" /></span>
              </div>
            </section>




              <section id="datePicker" v-if="endOption === 'until'">
                <div class="sectionTitle">End Date</div>
                <div class="tip">Leave blank if no end date</div>
                <div >
                  <DatePicker v-model="endDate" :class="{ 'hasError': endDateHasError }"  />
                </div>
                <div class="error-feedback" v-if="endDateHasError">End date can't be empty or earlier than the start date.</div>
              </section>

          </div>



        </div>


        <section id="datePicker" v-if="isBudget === '1'">
          <div class="sectionTitle">{{startDateLabel}}</div>
          <div >
            <DatePicker v-model="startDate" />
          </div>
        </section>



        <div v-if="isBudget==='0'">
          <section id="datePicker" >
            <div class="sectionTitle">Transaction Date</div>
            <div >
              <DatePicker v-model="tranDate" :class="{ 'hasError': tranDateHasError }"  />
            </div>
            <div class="error-feedback" v-if="futureTranDate">Transaction date can't be a future date.</div>
          </section>
        </div>





        <section class="tab enterAmount show" style="margin:70px 0;">
          <div class="sectionTitle">Amount</div>
          <div class="input-group mb-3" :class="{ 'hasError': tranAmountHasError }" >
            <span class="input-group-text" @click="amountAdj('minus')"><font-awesome-icon :icon="['fas', 'minus-circle']" /></span>
            <span class="input-group-text">{{this.familyCurrency}}</span>
            <input type="text" class="form-control tranAmount" aria-label="Amount" v-model="tranAmount">
            <span class="input-group-text"  @click="amountAdj('plus')"><font-awesome-icon :icon="['fas', 'plus-circle']" /></span>
          </div>

          <div style="display:flex;justify-content: center;margin-top:20px;">
            <div v-for="increment in increments" :key="increment.id">
              <increment :increment="increment" :incrementActiveId="incrementActiveId" @setIncrementAmount="onSetIncrementAmount"></increment>
            </div>
            <button class="btn clearBtn" @click="set2zero">clr</button>
          </div>

          <div class="negativeBalanceError" v-if="negativeBalanceError">Amount exceeds account balance.</div>
          <div class="negativeBalanceError" v-if="negativeBalanceErrorRelated">Amount exceeds the balance of the related account.</div>
        </section>




        <section id="tranDescContainer">
          <div class="sectionTitle">Transaction Description</div>
          <textarea class="form-control" :class="{ 'hasError': tranDescHasError }"  id="Details" rows="3" placeholder="" v-model="tranDesc"></textarea>
        </section>



        <section>
          <label class="form-label fromToLabel sectionTitle" for="RelatedAccounts"><span v-if="inOut==='in'">From</span><span v-if="inOut==='out'">To</span> Account</label>
          <select class="form-control validate" :class="{ 'hasError': relatedAcctHasError }" id="RelatedAccounts" v-model="relatedAccountId">
            <option v-for="(familyAccount) in familyRelatedBas" :key="familyAccount.BA_ID" :value="familyAccount.BA_ID">{{familyAccount.ACCOUNT_NAME}}</option>
            <option value="0">External Source</option>
          </select>
          <div class="balanceDiv" v-if="relatedAccountId">Balance: {{tranBaRelatedBalance}}</div>
        </section>

      </div>
    </div>



    <div class="deleteDiv d-flex" v-if="tranId">
      <hr>
      <button class="btn btn-danger" @click="showDeleteModal=true"><font-awesome-icon :icon="['fas', 'trash']" /> Delete</button>
    </div>

  </div>


  <div class="CompleteBtnDiv active" @click="saveTran">
    <button class="btn completeBtn active" :class="inOut+'Btn'"  :disabled="loading">
      <span v-show="loading" class="spinner-border spinner-border-sm" ></span>
      {{saveBtnTxt}}
    </button>
  </div>



  <div class="modalParent">
    <vue-final-modal v-model="showDeleteModal" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Are you sure you want to delete this {{tranType2}}?</h5>
      </div>

      <div class="modal-body">
        <div style="display:flex;justify-content: center;">
          <div class="btn delConfirmBtn delYes" @click="delTranOrSchedule"><font-awesome-icon :icon="['fas', 'trash']"/> Yes</div>
          <div class="btn delConfirmBtn delNo" @click="showDeleteModal=false"><font-awesome-icon :icon="['fas', 'times']"/> No</div>
        </div>
      </div>
    </vue-final-modal>
  </div>


  <div class="modalParent scheduleLimitReachedModal">
    <vue-final-modal v-model="showScheduleLimitReachedModal" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Schedule limit exceeded</h5>
      </div>

      <div class="modal-body">
        <div class="modal-body-msg">
          Too many schedules will slow down the application, so we carefully limit the number of allowed schedules per family to be {{this.$config.scheduleLimit}}. <br><br>Please contact support@goblinbank.com if you would like this limit increased.
        </div>
        <div style="display:flex;justify-content: center;">
          <div class="btn delConfirmBtn delNo" @click="showScheduleLimitReachedModal=false">Got it</div>
        </div>
      </div>
    </vue-final-modal>
  </div>


</template>

<script>
import Increment from '@/components/Increment';
import DatePicker from '@/components/DatePicker';
import {VueFinalModal } from 'vue-final-modal';
import currencies from "@/services/currencies.json";
import {datetime, RRule} from "rrule";

var firstUpcomingTranDate;

export default {
  name: 'Tran',
  props: {
    tranMode:String,
  },
  components: {
    Increment, DatePicker,
    VueFinalModal

  },

  data(){
    return{
      account: {},
      userInfo:{},
      isAdmin:false,
      me:{},

      baId:null,
      userId:null,
      tranId:null,
      //pageTitle:'',
      transactions:null,
      inOut:'',
      isBudget:'0',
      tranBaId:null,
      tranBaName:null,

      showBudgetTrans:true,

      familyCurrency:'$',
      centralBankBaId:null,


      familyBas:{},
      userBas:{},

      userTranAccounts:{},
      numIncreMode:'plus',
      tranAmount: '0',
      tranAmountHasError: false,
      incrementAmount:5,
      //saveBtnTxt:'Add New Transaction',
      increments: [
        {id:1, name:'$5',amount:5},
        {id:2, name:'$1',amount:1},
        {id:3, name:'¢25',amount:0.25},
        {id:4, name:'¢10',amount:0.10},
        {id:5, name:'¢5',amount:0.05},
        {id:6, name:'¢1',amount:0.01}
      ],
      incrementActiveId:1,

      weekdays:[
        {id:1, day:0, name:'Sunday',value:'SU',text:'S'},
        {id:2, day:1, name:'Monday',value:'MO',text:'M'},
        {id:3, day:2, name:'Tuesday',value:'TU',text:'T'},
        {id:4, day:3, name:'Wednesday',value:'WE',text:'W'},
        {id:5, day:4, name:'Thursday',value:'TH',text:'T'},
        {id:6, day:5, name:'Friday',value:'FR',text:'F'},
        {id:7, day:6, name:'Saturday',value:'SA',text:'S'}
      ],
      weekdaySelected:[],

      months:[
        {id:1, name:'January',value:'1',abbr:'Jan'},
        {id:2, name:'February',value:'2',abbr:'Feb'},
        {id:3, name:'March',value:'3',abbr:'Mar'},
        {id:4, name:'April',value:'4',abbr:'Apr'},
        {id:5, name:'May',value:'5',abbr:'May'},
        {id:6, name:'June',value:'6',abbr:'Jun'},
        {id:7, name:'July',value:'7',abbr:'Jul'},
        {id:8, name:'August',value:'8',abbr:'Aug'},
        {id:9, name:'September',value:'9',abbr:'Sep'},
        {id:10, name:'October',value:'10',abbr:'Oct'},
        {id:11, name:'November',value:'11',abbr:'Nov'},
        {id:12, name:'December',value:'12',abbr:'Dec'},
      ],

      byweekday:null,
      bysetpos:null,
      bymonthday:null,
      bymonth:null,
      monthlyRepeatOn:'dayofmonth',


      tranAccountHasError:false,
      tranDesc:'',
      tranDescHasError:false,
      relatedAccountId: '',
      autoConvert:'1',
      tranDate:  new Date(),
      tranDateYmd:'',
      tranDateHasError:false,
      futureTranDate:false,
      startDate: null,
      endDateHasError:false,
      relatedAcctHasError:false,
      negativeBalanceError:false,
      negativeBalanceErrorRelated:false,

      endOption:'never',

      endDate:  null,
      endDateYmd:'',
      repeatTimesOptions:99,
      interval:1,
      repeatTimes:10,
      repeatFrequency:'WEEKLY',
      repeatFrequencyOptions:[
        {id:1, name:'Never',value:'never', rruleValue:'never', text:''},
        {id:2, name:'Daily',value:'DAILY', rruleValue:'DAILY', text:'Day(s)'},
        {id:3, name:'Weekly',value:'WEEKLY', rruleValue:'WEEKLY', text:'Week(s)'},
        {id:4, name:'Monthly',value:'MONTHLY', rruleValue:'MONTHLY', ext:'Month(s)'},
        {id:7, name:'Yearly',value:'YEARLY', rruleValue:'YEARLY', text:'Year(s)'},

      ],

      schedule:{},
      scheduleFromDb:{},


      showDeleteModal:false,
      showPickBasModal:false,
      showScheduleLimitReachedModal:false,
      loading:false

    }
  },

  computed:{
    inOutTxt(){
      if(this.inOut === 'in'){
        return ' (Money In)';
      }else if(this.inOut === 'out'){
        return ' (Money Out)';
      }else{
        return '';
      }
    },

    saveBtnTxt() {
      if(this.tranMode === 'edit'){
        return 'Update ' + this.tranType + ' #' + this.tranId;
      }else{
        return 'Add New ' + this.tranType +this.inOutTxt;
      }
    },

    tranType(){
      return this.isBudget === '1' ? 'Schedule' : 'Transaction';
    },

    tranType2(){
      return this.isBudget === '1' ? 'schedule' : 'transaction';
    },

    pageTitle() {
      if(this.tranMode === 'edit'){
        return "Edit "+ this.tranType +" #" + this.tranId;

      }else{
        return 'Add New ' + this.tranType;
      }
    },

    intervalTxt(){
      return this.repeatFrequencyOptions.filter(option=>option.value === this.repeatFrequency )[0]['text'];
    },



    familyRelatedBas(){
      return this.familyBas.filter(ba=>ba.BA_ID !== this.tranBaId );
    },

    tranBa(){
      return this.familyBas.filter(ba=>ba.BA_ID === this.tranBaId )[0];
    },

    tranBaRelated(){
      return this.relatedAccountId === '0' ? {} : this.familyBas.filter(ba=>ba.BA_ID === this.relatedAccountId )[0];
    },

    tranBaBalance(){
      return this.tranBa ? this.tranBa.BALANCE : '0';
    },

    tranBaRelatedBalance(){
      return this.relatedAccountId === '0' ? '999999999999' : this.tranBaRelated ? this.tranBaRelated.BALANCE : '0';
    },

    startDateLabel(){
      return this.repeatFrequency === 'never' ? 'Transaction Date' : 'Start Date';
    }

  },
  created() {
    window.scrollTo(0, 0);

    this.inOut = this.$route.query.io;
    this.userId = this.$route.query.userId;
    this.tranBaId = this.$route.query.tranBaId;
    this.tranId = this.$route.query.tranId;
    this.isBudget=this.$route.query.isSchedule === '1' ?  '1' : '0';
    //console.log(this.isBudget);
    //console.log(this.inOut);
    //console.log(this.$root.me);
    this.me = this.$root.getLocalUserInfo();

    this.isAdmin = this.me.FAMILY_ADMIN === '1';
    if(!this.isAdmin) this.inOut = 'out';

    const familyUsers = JSON.parse(localStorage.getItem('familyUsers'));
    this.userInfo = familyUsers.filter(u=>u.ID === this.userId)[0];
    //console.log(this.userInfo);

    const familyInfo = JSON.parse(localStorage.getItem('familyInfo'));
    this.familyCurrency = currencies.filter(c => c.cc === familyInfo.CURRENCY_CODE)[0].symbol;

    this.familyBas = JSON.parse(localStorage.getItem('familyBas'));
    this.userBas = this.familyBas.filter(ba => ba.USER_ID === this.userInfo.ID);
    this.centralBankBaId = this.familyBas.filter(ba => ba.CENTRAL_BANK === '1')[0].BA_ID;
    this.relatedAccountId = this.tranBaId === this.centralBankBaId ? null  : this.centralBankBaId;

    //console.log(this.userBas);
    if(!this.tranBaId || this.tranBaId === '') this.tranBaId = this.userBas[0].BA_ID;

    this.increments = [
      {id:1, name:this.familyCurrency+'5',amount:5},
      {id:2, name:this.familyCurrency+'1',amount:1},
      {id:3, name:'¢25',amount:0.25},
      {id:4, name:'¢10',amount:0.10},
      {id:5, name:'¢5',amount:0.05},
      {id:6, name:'¢1',amount:0.01}
    ];
  },


  watch: {

    tranAmount(newVal, oldVal) {
      this.tranAmount = this.vueNumberFormat(this.tranAmount);
    },

    transAsOfDate(newVal, oldVal) {
      this.getTransactions();
    },


  },

  mounted() {
    document.title = this.pageTitle;
    this.setDefaults();
    if(this.tranId) {
      if(this.isBudget === '1'){
        this.getSchedule(this.tranId);
        //console.log(this.isBudget);
      }else{
        this.getTran(this.tranId);

      }
    }

    //console.log(this.isBudget);
  },

  methods:{


    tranBtnClicked:function(btn){
      this.$root.playClickSound();

      this.inOut = btn;

      this.tranDateHasError = false;
      this.tranDescHasError = false;
      this.tranAmountHasError = false;
      this.futureTranDate = false;

      if(this.isBudget === null) this.isBudget = '0';




    },

    switchActualBudget(tranType){
      this.$root.playClickSound();
      if(!this.tranId){
        this.isBudget = tranType === 'actual' ? '0' : '1';
        //console.log(this.isBudget);
        //console.log(this.startDate);
        if(!this.isAdmin){
          this.inOut = 'out';
        }
      }


    },

    toggleShowBudget(){
      this.showBudgetTrans = !this.showBudgetTrans;
      this.getTransactions();
    },

    toggleAutoConvert(){
      this.autoConvert = this.autoConvert === '1' ? '0' : '1';
    },


    amountAdj:function(dir){
      this.$root.playClickSound();
      if(dir==='plus'){
        this.tranAmount = parseFloat(this.numDeformat(this.tranAmount)) + parseFloat(this.incrementAmount);
      }
      if(dir==='minus'){
        this.tranAmount = parseFloat(this.numDeformat(this.tranAmount)) - parseFloat(this.incrementAmount);
        if(this.tranAmount < 0)  this.tranAmount = 0;
      }

    },

    intervalAdj:function(dir){
      this.$root.playClickSound();
      if(dir==='plus'){
        this.interval++;
      }
      if(dir==='minus'){
        this.interval--;
        if(this.interval < 1)  this.interval = 1;
      }

    },

    toggleWeekdaySelection(a){
      if(this.weekdaySelected.includes(a)){
        this.weekdaySelected.splice(this.weekdaySelected.indexOf(a), 1)
      }else{
        this.weekdaySelected.push(a);
      }
      //console.log(this.weekdaySelected);
    },

    selectRadio(a){
      this.monthlyRepeatOn = a;
      if(a==='weekday'){
        this.bymonthday = null;
      }
      if(a==='dayofmonth'){
        this.bysetpos = null;
        this.byweekday = null;
      }
    },


    set2zero:function(){
      this.tranAmount = 0;
    },
    onSetIncrementAmount(increment) {
      this.incrementActiveId = increment.id;
      this.incrementAmount = increment.amount;
    },

    numDeformat:function(n){
      return parseFloat(n.replace(/,/g, ''));
    },
    getLocalDateString:function(d){
/*      console.log(d.toLocaleDateString('en-CA'));
      console.log(d);
      var localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log(localTimeZone);
      var localDate = d.toLocaleDateString('en-CA', {timeZone: localTimeZone});
      console.log(localDate);
      console.log(new Date());
      console.log(this.$root.formatDateYyyymmdd(d));*/


      return this.$root.formatDateYyyymmddLocal(d);
    },



    getLocalDT0000(d){
      //I am in EST
      //when UTC is 01/01/2024 08:00 UTC, local is 01/01/2024 03:00 EST, this will return 01/01/2024 00:00 EST
      //when UTC is 01/01/2024 01:00 UTC, local is 12/31/2023 20:00 EST, this will return 12/31/2023 00:00 EST
      if(!d) d = new Date();
      d.setHours(0,0,0,0);
      return d;
    },

    getUTC0000(d){
      //I am in EST
      //when UTC is 01/01/2024 08:00 UTC, local is 01/01/2024 03:00 EST, this will return 01/01/2024 00:00 UTC
      //when UTC is 01/01/2024 01:00 UTC, local is 12/31/2023 20:00 EST, this will return 01/01/2024 00:00 UTC

      if(!d) d = new Date();
      d.setUTCHours(0,0,0,0);
      return d;
    },

    getTran(tranId){
      this.axios
          .post(this.$hostApi,{task:'getTran',tranId:tranId,userId:this.userId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            const tran = response.data;
            //console.log(tran);

            this.tranBaId = tran.ACCOUNT_ID;
            //this.isBudget = tran.IS_BUDGET;
            this.relatedAccountId = tran.ACCOUNT_ID_RELATED;

            this.tranAmount = Math.abs(parseFloat(tran.AMOUNT));
            /*const td = new Date(tran.TRAN_DATE);
            td.setDate(td.getDate() + 1);
            this.tranDate = td;*/

            //this.tranDate = new Date(tran.TRAN_DATE);
            //this.tranDate = tran.TRAN_DATE + " 00:00";

            let d = new Date(tran.TRAN_DATE);
            //this.tranDate = d.toLocaleDateString('en-CA', {timeZone: "UTC"})
            //this.tranDate = d.setMinutes(d.getMinutes() + this.$root.timezoneOffset);
            let d2 = d.setMinutes(d.getMinutes() + this.$root.timezoneOffset);
            this.tranDate =  new Date(d2);

            this.tranDesc = tran.DETAILS;
            this.autoConvert = tran.AUTO_CONVERT;
            if(parseFloat(tran.AMOUNT) >= 0){
              this.inOut = 'in';
            }else{
              this.inOut = 'out';
            }



          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },


    getSchedule(scheduleId){
      this.axios
          .post(this.$hostApi,{task:'getSchedule',scheduleId:scheduleId,userId:this.userId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            const tran = response.data;
            this.scheduleFromDb = tran;
            //console.log(tran);

            this.tranBaId = tran.ACCOUNT_ID;
            this.relatedAccountId = tran.ACCOUNT_ID_RELATED;

            this.tranAmount = Math.abs(parseFloat(tran.AMOUNT));
            /*const td = new Date(tran.TRAN_DATE);
            td.setDate(td.getDate() + 1);
            this.tranDate = td;*/

            //this.tranDate = new Date(tran.TRAN_DATE);
            //this.tranDate = tran.START_DATE + " 00:00";

            this.tranDesc = tran.DETAILS;
            if(parseFloat(tran.AMOUNT) >= 0){
              this.inOut = 'in';
            }else{
              this.inOut = 'out';
            }


            this.parseScheduleDb(tran.SCHEDULE);


          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },



    saveTran: async function(){
      //console.log(new Date());
      //console.log('start date is '+this.startDate);
      //console.log(this.startDate);

      this.$root.playClickSound();
      this.loading = true;

      const tranAmount2 = this.numDeformat(this.tranAmount);

      const amount = this.inOut === 'in' ? tranAmount2 : tranAmount2 * -1;
      const tranDesc = this.tranDesc;
      const relatedAccountId = this.relatedAccountId;

      this.tranAccountHasError = false;
      this.tranAmountHasError = false;
      this.tranDescHasError = false;
      this.tranDateHasError = false;
      this.futureTranDate = false;
      this.relatedAcctHasError = false;
      this.negativeBalanceError = false;
      this.negativeBalanceErrorRelated = false;

      if(tranAmount2 <= 0 || tranAmount2 > 100000) {this.tranAmountHasError = true; }
      if(this.isBudget==='0' && this.inOut === 'out' && this.numDeformat(this.tranBaBalance)-tranAmount2 < 0){
        this.negativeBalanceError = true;
      }
      if(this.isBudget==='0' && this.inOut === 'in' && this.numDeformat(this.tranBaRelatedBalance)-tranAmount2 < 0){
        this.negativeBalanceErrorRelated = true;
      }


      if(tranDesc.length === 0){this.tranDescHasError = true; }
      if(this.tranDate.length === 0){this.tranDateHasError = true; }
      if(this.isBudget === '0'){
        //may need rework
        this.tranDate.setHours(0,0,0,0);
        if(this.tranDate > new Date()) {
          console.log(this.tranDate);
          console.log(new Date());

          this.tranDateHasError = true;
          this.futureTranDate = true;
        }
      }

      if(this.isBudget === '1'){

        //this.startDate.setHours(0,0,0,0);

        if(this.endOption === 'until'){
          if(this.endDate) this.endDate.setHours(0,0,0,0);
          if(!this.endDate || (this.endDate && this.startDate > this.endDate)) {
            this.endDateHasError = true;
          }
        }

        /*        if(this.repeatFrequency === 'yearly'){
                  var bymonthdate = this.bymonth + '/' + this.bymonthday + '/2023';
                  console.log(bymonthdate);
                  let dateObj = new Date(bymonthdate);
                  console.log(dateObj);
                  if(!isNaN(dateObj)){
                    console.log('valid');
                  }else{
                    console.log('invalid');
                  }
                }*/

      }

      if(!this.tranBaId){this.tranAccountHasError = true; }
      if(!this.relatedAccountId){this.relatedAcctHasError = true; }
      //console.log(this.relatedAccountId);


      if(this.tranAccountHasError || this.tranAmountHasError || this.tranDescHasError || this.tranDateHasError || this.futureTranDate || this.relatedAcctHasError || this.negativeBalanceError || this.negativeBalanceErrorRelated){
        this.loading = false;
        return;
      }

      if(this.isBudget !== '1'){
        this.tranDateYmd = this.getLocalDateString(this.tranDate);
      }


      if(this.tranId){
        if(this.tranMode === 'edit') {
          if (this.isBudget === '1') {
            this.createScheduleDb();

            //all we need here is the first tran date
            let schedules = [{
              SCHEDULE: JSON.stringify(this.schedule),
            }];

            var allUpcomingTrans = this.$root.getAllUpcomingTrans(schedules, false, false, null);
            //console.log(allUpcomingTrans);
            let trans = allUpcomingTrans.filter(tran => tran.TRAN_DATE > this.scheduleFromDb.PREV_DATE);
            //console.log(this.scheduleFromDb);
            //console.log(trans);


            let firstUpcomingTranDate = trans[0] ? trans[0].TRAN_DATE : this.getLocalDT0000();
            //console.log(firstUpcomingTranDate);

            await this.axios
                .post(this.$hostApi, {
                  task: 'updateSchedule',
                  scheduleId: this.tranId,
                  tranBaId: this.tranBaId,
                  amount: amount,
                  tranDesc: tranDesc,
                  relatedAccountId: relatedAccountId,
                  schedule: this.schedule,
                  nextDate: firstUpcomingTranDate
                }, {
                  headers: {
                    'token': localStorage.getItem('user')
                  }
                });
            await this.$root.doRefresh();
            this.loading = false;
            //this.$root.gotoRoute({ path: '/member', query: { userId: this.userInfo.ID }});
            this.$router.go(-1);

          } else {
            await this.axios
                .post(this.$hostApi, {
                  task: 'updateTran',
                  tranBaId: this.tranBaId,
                  tranId: this.tranId,
                  amount: amount,
                  tranDesc: tranDesc,
                  tranDate: this.tranDateYmd,
                  autoConvert: this.autoConvert,
                  tranBaIdRelated:this.relatedAccountId,
                }, {
                  headers: {
                    'token': localStorage.getItem('user')
                  }
                });
            await this.$root.doRefresh();
            this.loading = false;
            //this.$root.gotoRoute({ path: '/member', query: { userId: this.userInfo.ID }});
            this.$router.go(-1);

          }
        }

      }else{
        if(this.isBudget === '1'){

          let existingSchedules = JSON.parse(localStorage.getItem('schedules'));
          if(existingSchedules.length >= this.$config.scheduleLimit){
            this.showScheduleLimitReachedModal = true;
            this.loading = false;
            return;
          }

          this.createScheduleDb();
          let schedules = [{
            SCHEDULE:JSON.stringify(this.schedule),
          }];


          let allUpcomingTrans = this.$root.getAllUpcomingTrans(schedules,false,false,null);
          //console.log(allUpcomingTrans);
          var firstTran = allUpcomingTrans[0];
          let firstUpcomingTranDate = firstTran.TRAN_DATE;

          await this.axios
              .post(this.$hostApi,{task:'addNewSchedule',tranBaId:this.tranBaId, amount:amount, tranDesc:tranDesc, relatedAccountId:relatedAccountId, schedule:this.schedule, nextDate: firstUpcomingTranDate, timezoneOffset:this.$root.timezoneOffset, localTimezone:this.$root.localTimeZone},{
                headers:{
                  'token':localStorage.getItem('user')
                }
              });
          await this.$root.doRefresh();
          this.$router.go(-1);
          console.log('added');

        }else{
          await this.axios
              .post(this.$hostApi,{task:'addNewTran',tranBaId:this.tranBaId, isBudget:this.isBudget, amount:amount, tranDesc:tranDesc, repeatFrequency:this.repeatFrequency, tranDate:this.tranDateYmd, endDate:this.endDateYmd, autoConvert:this.autoConvert, repeatTimes:this.repeatTimes, relatedAccountId:relatedAccountId},{
                headers:{
                  'token':localStorage.getItem('user')
                }
              });

          await this.$root.doRefresh();
          this.loading = false;
          //this.$root.gotoRoute({ path: '/member', query: { userId: this.userInfo.ID }});
          this.$router.go(-1);

        }
      }
    },

    delTranOrSchedule:function(){
      if(this.isBudget === '1'){
        this.delSchedule();
      }else{
        this.delTran();
      }
    },


    delTran: async function(){
      this.$root.playClickSound();
      await this.axios
          .post(this.$hostApi,{task:'delTran', tranBaId:this.tranBaId, tranId:this.tranId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });
      this.tranId = null;
      this.showDeleteModal = false;
      await this.$root.doRefresh();
      this.$router.go(-1);
    },

    delSchedule: async function(){
      this.$root.playClickSound();
      await this.axios
          .post(this.$hostApi,{task:'delSchedule', scheduleId:this.tranId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });
      this.showDeleteModal = false;
      await this.$root.doRefresh();
      this.$router.go(-1);
    },





    formReset: function(){
      this.tranId = null;
      this.repeatTimes = 10;
      this.repeatFrequency = 'oneoff';
      this.tranAmount = '0';
      //this.incrementAmount = 5;
      this.tranDesc = '';

      this.tranDate = new Date();
    },


    setDefaults(){
      var today = new Date();
      var todayWeekday =  this.weekdays.filter(d=>d.day === today.getDay())[0];
      this.weekdaySelected.push(todayWeekday.value);
      this.bymonthday = today.getDate();
      this.bysetpos = 1;
      this.byweekday = 'MO';
      var todayMonth = this.months.filter(m=>m.id === today.getMonth() + 1)[0];
      this.bymonth = todayMonth.value;
      this.tranDate = new Date();
      this.startDate = this.getLocalDT0000();
      //console.log(this.startDate);
    },


    showDeleteModalFunc:function(tranId){
      this.$root.playClickSound();
      this.showDeleteModal = true;
      this.tranId = tranId;
    },


    resetRelatedAccount(){
      if(this.tranBaId === this.centralBankBaId){
        this.relatedAccountId = null;
      }
    },

    gotoBc(){
      this.$root.gotoRoute({ path: '/bc/member', query: { userId: this.userId, initialTransLoad: true } })
    },



    createScheduleDb(){
      //console.log(this.startDate);

      var rule = {};
      rule.freq = RRule[this.repeatFrequency]; this.schedule.freq = this.repeatFrequency;
      rule.interval = this.interval; this.schedule.interval = this.interval;
      rule.dtstart = this.startDate; this.schedule.dtstart = this.getLocalDateString(this.startDate);
      //console.log(this.startDate);
      //console.log(rule.dtstart);

      if(this.repeatFrequency === 'WEEKLY'){

        rule.byweekday = this.weekdaySelected.map(weekday =>RRule[weekday]); this.schedule.byweekday = this.weekdaySelected;
      }

      if(this.repeatFrequency === 'MONTHLY' || this.repeatFrequency === 'YEARLY'){
        //console.log(this.monthlyRepeatOn);
        if(this.monthlyRepeatOn === 'dayofmonth'){
          //console.log(this.bymonthday);
          rule.bymonthday = [this.bymonthday]; this.schedule.bymonthday = rule.bymonthday;
        }

        if(this.monthlyRepeatOn === 'weekday'){
          //console.log(this.byweekday);
          rule.byweekday = [RRule[this.byweekday]]; this.schedule.byweekday = [this.byweekday];
          rule.bysetpos = [this.bysetpos]; this.schedule.bysetpos = rule.bysetpos;
        }

      }

      if(this.repeatFrequency === 'YEARLY'){
        //console.log(this.bymonth);
        rule.bymonth = [parseInt(this.bymonth)]; this.schedule.bymonth = rule.bymonth;
      }

      if(this.repeatFrequency === 'never'){
        //console.log(this.bymonth);
        rule.freq = RRule.DAILY;
        rule.count = 1; this.schedule.count = 1;
      }



      if(this.endOption === 'until'){
        rule.until = this.endDate; this.schedule.until = this.getLocalDateString(this.endDate);
      }else{
        rule.until = new Date('2078-09-29');
      }

      if(this.endOption === 'count'){
        rule.count = this.repeatTimes; this.schedule.count = rule.count;
      }

      //console.log(rule);

      //var rrule = new RRule(rule);

      /*var rule = new RRule({
        freq: RRule[freq.rruleValue],
        count:this.repeatTimes,
        interval: this.interval,
        dtstart: this.startDate,
        until: this.endDate
      });*/

      //console.log(JSON.stringify(rrule));
      //console.log(rrule.toString());
      //console.log(rrule.toText());
      //console.log(rrule.all());

      /*      var dates = rrule.all().map(date =>
                //date.toLocaleDateString('en-CA',{timeZone: "UTC"})
                this.getLocalDateString(date)
            );





            console.log(dates);*/

    },


    parseScheduleDb(schedule){
      //console.log(schedule);
      var scheduleDb = JSON.parse(schedule);
      //console.log(scheduleDb);
      this.repeatFrequency = scheduleDb.freq;
      this.interval = scheduleDb.interval;
      //this.startDate = scheduleDb.dtstart + " 00:00";
      let d = new Date(scheduleDb.dtstart);
      //console.log(d);
      //this.startDate = d.toLocaleDateString('en-CA', {timeZone: "UTC"})
      let d2 = d.setMinutes(d.getMinutes() + this.$root.timezoneOffset);
      this.startDate =  new Date(d2);
      //console.log(this.startDate);

      if(scheduleDb.freq === 'WEEKLY'){
        this.weekdaySelected = scheduleDb.byweekday;
      }

      if(scheduleDb.freq === 'MONTHLY' || scheduleDb.freq === 'YEARLY'){
        if(scheduleDb.bymonthday){
          this.monthlyRepeatOn = 'dayofmonth';
          this.bymonthday = scheduleDb.bymonthday[0];
        }

        if(scheduleDb.byweekday){
          this.monthlyRepeatOn = 'weekday';
          this.byweekday = scheduleDb.byweekday[0];
          this.bysetpos = scheduleDb.bysetpos[0];
        }
      }

      if(scheduleDb.freq === 'YEARLY'){
        this.bymonth = scheduleDb.bymonth[0];
      }

      if(scheduleDb.until){
        if(scheduleDb.until === '2078-09-29'){
          this.endOption = 'never'
        }else{
          this.endOption = 'until'
          //this.endDate = scheduleDb.endDate;
          let d = new Date(scheduleDb.until);
          console.log(d);
          //this.startDate = d.toLocaleDateString('en-CA', {timeZone: "UTC"})
          let d2 = d.setMinutes(d.getMinutes() + this.$root.timezoneOffset);
          this.endDate =  new Date(d2);
        }

      }

      if(scheduleDb.count){
        this.endOption = 'count';
        this.count = scheduleDb.count;
      }
    },





  }
}
</script>

<style scoped>

#mainContainer{
  margin:70px auto 100px auto;
  padding:20px 10px 10px 10px;
}

section{
  margin:30px 0;
}

.sectionTitle{
  color: #098acc;
  font-weight: bold;
  margin-bottom:10px;
}

.tranBtn, .tranTypeBtn{
  margin:5px;
  width:46%;
  background-color:#f5f5f5;
}





.inBtn.active{
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.outBtn.active{
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}



/*>>>>>>>List 2>>>>>>>>>>>>>>>>>>*/

.list2 .avatar img{
  width:40px;
  border:2px solid #9c9c9c;
  border-radius: 50%;
  padding:3px;
}

.list2 li.active{
  border:2px solid #0e90d2;
}

.list2 li{
  position:relative;
  background-color: #f5f5f5;
  border: 2px solid #fff;
  margin: 1px;
}

.list2 li.isBudget{
  background-color: #f2f5a8;
}

.email{
  font-size:12px;
}




.selectDiv{
  position:absolute;
  right:10px;
  width:50px;
}

.selectSpot{
  color:#fff;
}

li.active .selectSpot{
  color: #0e90d2;
}





/*<<<<<<<<<<<<<<List 2<<<<<<<<<<<<<<*/



input.tranAmount{
  height:120px;
  font-size:120px;
  font-weight:bold;
  text-align:center;
  font-family: Arial, sans-serif;
  line-height: 120px;
}



#tranDescContainer{
  margin-bottom:50px;
}

#Details{
  background-color: #f5f5f5;
}

.CompleteBtnDiv{
  width: calc(100vw + 20px);
  left:-10px;
  height:70px;
  line-height: 70px;
  position:fixed;bottom:0;display:flex;align-items:center;justify-content: center;z-index:1001;
  padding:0;

}


.completeBtn{
  width:100%;
  border-radius: unset;
  height:100%;
  background-color: #ffd603;
  padding:0;
}

.fromToLabel{
  text-align: left;
  float:left;
  font-weight:bold;
}



@media only screen and (max-width: 600px) {


  input.tranAmount{
    height:60px;
    font-size:50px;
    font-weight:bold;
    line-height: 60px;
  }
}









.clearBtn{
  font-size:10px;
  margin:5px;
}

.hasError{
  border:1px solid red;
}




.flex {
  display: flex;
  justify-content: left;
  align-items: center;
}

#actionBtnsContainer {
  position: fixed;
  bottom: 100px;
  right: 50px;
  height: 45px;
  color: #fff;
}

#actionBtnsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionDiv {
  margin-left: 10px;

}

.actionBtn {
  width: 45px;
  height: 45px;
  background-color: #0dcaf0;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.actionTxt {
  color: #3b3b3b;
  font-size: 8px;
  height: 10px;
  text-align: center;
}

.modal-dialog.fullwidth {
  width: 100% !important;
  max-width: 100% !important;
}

.delYes {
  background-color: red;
  color: #fff;
}

.delConfirmBtn {
  text-align: center;
  border: 1px solid #f5f5f5;
  margin: 10px;
}

.delNo {
  background-color: #e4e4e4;
  color: #3b3b3b;
}

.delConfirmBtn svg {
  margin-right: 10px;
}


.modal-body-msg{
  margin:20px;
}







.tranAccounts li{
  margin:5px 0;
  background-color: #e8e5e5;
  padding:3px 10px;
  border-radius: 2px;
  font-size:14px;
}

.error-feedback{
  font-size:10px;
  color:red;

}

.extend{
  margin-left:20px;
  font-size:12px;
  padding:5px 10px;
}

.expireIcon{
  margin-right:10px;
}

.expireBanner{
  display:flex;
  align-items: center;
}


.tranTypeBtn.active{
  color: #fff;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.showBudgetTxt{
  font-size:12px;
  margin-right:10px;
}

.showBudgetDiv{
  margin:15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tip{
  font-size:10px;
  font-style: italic;
  color:#9c9c9c;
}



/*modal*/
.modalParent :deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalParent :deep(.modal-content){
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width:unset;

}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 14px;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0 solid #e2e8f0;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.toggleRow{
  display:flex;
  justify-content: space-between;
  margin-top:30px;
}

.toggleFa{
  font-size:30px;
  margin-right:10px;
}
.balanceDiv{
  padding:5px 10px;
  font-size:12px;
}

.negativeBalanceError{
  color:red;
}

.history{
  width:30px;
}

.intervalTxt{
  padding:10px 20px;
}

.interval{
  text-align: center;
}

#weekdaysUl{
  display:flex;
  align-items: center;
  justify-content: center;
}

#weekdaysUl li{
  background-color: #dedcdc;
  padding:16px;
  border-radius: 50%;
  width:30px;
  height:30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:5px;

}

#weekdaysUl .active{
  background-color: #ec618f;
  color:#ffffff;
}

.form-check-input{
  margin-right:10px;
}

.subsection{
  margin-bottom:15px;
}

#monthlyRepeatOnUl .radioRow{
  margin-bottom:5px;
}

.radioRow label{
  flex-grow: 1;
}



.deleteDiv{
  justify-content: center;
  margin-top:30px;
}

.deleteDiv .btn{
  padding:10px 30px;
}

.scheduleLimitReachedModal .modal-body-msg{
  font-size:12px;
}


</style>
